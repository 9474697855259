.catalog{
padding: 20px 0;
width: 1320px;
margin: 0 auto;
display: flex;
flex-direction: column;
background-color: var(--background-color);
}

.categories{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
}
.title-categories{
    margin: 0 0 30px;

}