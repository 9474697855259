.category-start {
    width: 90%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    justify-self: end;
    text-decoration: none;
    transition: box-shadow 0.3s ease-out, background-color 0.3s ease-out, transform 0.3s ease-out !important;
}

.category-start:focus {
    text-decoration: none;
}

.category-start:hover {
    background-color:  var(--background-color);
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
}

.category-start-name{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-top: 10px;
}

.category-start-discription{
    margin: 0 0 10px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: "SF Pro Rounded Regular", sans-serif;

}

.category-start-img-background{
    margin: 10px;
    border-radius: 10px;
}

.category-start-img{
    width: 40px;
    height: 40px;
    transition: filter  0.3s ease-in-out;
}


