.product-card-grid-sale{
    display: flex;
    height: 400px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    position: relative;
    background: linear-gradient(to bottom, transparent 0%, color-mix(in srgb, var(--accent-color), transparent 95%) 0%);
    cursor: pointer;
    position: relative;
}

.discount{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 2px 7px;
    border-bottom-left-radius: 10px;
    align-items: center;
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    background-color: var(--accent-color);
    font-size: 1.1rem;
    color: color-mix(in srgb, var(--contrast-color), transparent 10%) ;
}
.product-card-grid-img-bg-sale {
    display: inline-block;
    width: 100%;
    height: 250px;
    background-color: white;
    padding: 10px; /* Отступы внутри контейнера */
}

.product-card-grid-sale:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
}

.product-card-grid-img-sale {
    position: relative;
    z-index: 0;
    border-radius: 30px;
    overflow: hidden;
    padding: 1rem;
    object-fit: contain;
    height: 80%;
    width: 100%; /* Устанавливаем авто ширину */
}

.product-card-grid-text-sale {
    width: 100%;
    font-size: 1rem;
    padding: 1rem;
}

.product-card-grid-name-sale {
    font-family: 'SF Pro Rounded Regular', sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    max-height: 2rem;
    color: var(--heading-color-dark);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
}

.product-card-grid-name-sale::after {
    content: ''; /* Пустое содержимое */
    display: block;
    height: 1rem; /* Высота строки */
    visibility: hidden; /* Невидимый элемент */
}

.product-card-grid-brand-sale{
    display: flex;
    justify-content: start; 
    font-family: 'SF Pro Rounded Regular', sans-serif; /* Шрифт */
    font-style: italic;
    padding: 5px 0 10px;
}

.product-card-grid-cost-sale {
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    font-size: 1.1rem;
    margin-top: 1rem;
    color: var(--heading-color-dark);
}

.product-card-grid-stock-sale {
    position: absolute; /* Абсолютное позиционирование */
    bottom: 1rem; /* Отступ снизу */
    right: 1rem; /* Отступ справа */
    font-family: 'SF Pro Rounded Regular', sans-serif; /* Шрифт */
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    text-decoration: line-through;
}
