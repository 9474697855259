.product-card {
    transition: all 0.2s ease-in-out;
}

.product-card-grid {
    display: flex;
    height: 400px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    position: relative;
    background: linear-gradient(to bottom, transparent 0%, color-mix(in srgb, var(--accent-color), transparent 95%) 0%);
    cursor: pointer;
    width: 235px;
}

.product-card-grid:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
}

.product-card-grid-img-bg {
    transition: height 0.2s ease-in-out;
    display: inline-block;
    width: 100%;
    height: 260px;
    background-color: white;
    padding: 10px; /* Отступы внутри контейнера */
}

.product-card-grid-img-bg.hovered {
    height: 220px;
}

.product-card-grid-img {
    border-radius: 30px;
    overflow: hidden;
    padding: 1rem;
    object-fit: contain;
    height: 90%;
    width: 100%; /* Устанавливаем авто ширину */
}

.product-card-grid-img-hint {
    border-radius: 30px;
    overflow: hidden;
    padding: 1rem;
    object-fit: contain;
    max-height: 350px;
}

.product-card-grid-text {
    display: flex;
    flex-direction: column;
    height: 140px;
    font-size: 1rem;
    padding: 1rem;
    justify-content: space-between;
}

.product-card-grid-name {
    font-family: 'SF Pro Rounded Regular', sans-serif;
    font-size: 1rem;
    line-height: 1rem;
    max-height: 2rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    margin-bottom: 1rem;
}

.product-card-grid-name::after {
    content: ''; /* Пустое содержимое */
    display: block;
    height: 1rem; /* Высота строки */
    visibility: hidden; /* Невидимый элемент */
}

.product-card-grid-retailccst-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.product-card-grid-retailcost-icon {
    width: 15px;
    object-fit: contain;
    margin-right: 15px;
}

.product-card-grid-retailcost {
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 1.1rem;
}

.product-card-grid-wholesale-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.product-card-grid-wholesale-icon {
    width: 15px;
    object-fit: contain;
    margin-right: 15px;
}

.product-card-grid-wholesale {
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    font-size: 1.1rem;
}

.product-card-grid-stock {
    position: absolute; /* Абсолютное позиционирование */
    bottom: 1rem; /* Отступ снизу */
    right: 1rem; /* Отступ справа */
    font-family: 'SF Pro Rounded Regular', sans-serif; /* Шрифт */
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: bottom 0.2s ease-in-out;
}

.product-card-grid-stock.hovered {
    bottom: 56px;
}

.product-card-grid-cart-container {
    transition: height 0.2s ease-in-out;
    height: 0;
    width: 100%;
}

.product-card-grid-cart-container.hovered {
    height: 40px;
}

.product-card-grid-cart-container .add {
    height: 100%;
    width: 100%;
    background-color: var(--rs-btn-primary-bg);
    border: none;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1rem;
    color: white;
    border-radius: 0 0 10px 10px;
    transition: all 0.2s ease-in-out;
}

.product-card-grid-cart-container .remove {
    height: 100%;
    width: 100%;
    background-color: var(--rs-btn-default-hover-bg);
    border: none;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1rem;
    color: var(--default-color);
    border-radius: 0 0 10px 10px;
    transition: all 0.2s ease-in-out;
}
