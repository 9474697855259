.smart-input {
    position: relative;
    margin: 30px 0;
}

.smart-input:first-child {
    margin: 0px 0 30px;
}

.smart-input input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid lightblue;
    border-radius: 10px;
}

.smart-input label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #999;
    pointer-events: none;
    transition: all 0.3s ease;
}

.smart-input input:focus + .label,
.smart-input input:not(:placeholder-shown) + .label {
    top: -10px;
    left: 10px;
    font-size: 12px;
    color: #333;
}