.cart{
    margin: auto 0 auto auto;
    position: relative;
}

.cart-btn {
    background-color: var(--accent-color);
    border-radius: 5px;
    transition: 0.5s;
    border: 2px solid var(--accent-color);
    color: var(--contrast-color);
    height: 38px;
    padding: 5px;
    display: flex;
    margin: auto 0 auto auto;
}

.cart-btn:hover, .cart-btn:hover:focus {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--contrast-color), transparent 20%);
    border: 2px solid var(--accent-color);
}

.cart-btn-count{
    position: absolute;
    top: -20%;
    right: -40%;
    color:var(--accent-color);
    padding: 1px 7px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid var(--accent-color);
    text-align: center;
    vertical-align: center;
    font-size: 12px;
}

.cart-btn-img {
    margin: auto;
    height: 90%;
    width: 90%;
    fill: var(--contrast-color);
}

.cart-btn:hover .cart-btn-img {
    fill: var(--accent-color);
}

.cart-modal-container {
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    position: fixed;
    width: 450px;
    border-radius: 10px;
    margin: 80px 20px auto auto;
    background-color: rgba(255, 255, 255, 0.7);
    border: 2px solid color-mix(in srgb, var(--accent-color), transparent 50%);
    padding: 20px;
    z-index: 17;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.cart-items-container {
    margin: 25px 0 0;
    height: 340px;
    overflow-y: auto;
    scrollbar-color: var(--accent-color) color-mix(in srgb, var(--accent-color), white 95%);
    scrollbar-width: thin;
    scrollbar-gutter: stable;
}

.cart-title {
    position: absolute;
    left: 20px;
    top: 12px;
    font-size: 1.1rem;
    font-family: 'SF Pro Rounded Semibold', sans-serif;
}

.checkout-btn {
    display: block;
    margin-top: 10px;
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
}

.checkout-btn:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 10%);
}

.cart-modal-container h4 {
    margin: 0;
}

.close-btn {
    margin-top: 10px;
}

.cart-empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
}

.cart-product-card {
    height: 80px;
    width: 100%;
    display: grid;
    grid-template-columns:1fr 2.5fr 1.5fr;
    grid-template-rows:1fr;
    border: 1px solid color-mix(in srgb, var(--accent-color), transparent 80%);
    border-radius: 5px;
    background-color: white;
    margin: 5px 0;
}

.cart-img-div {
    height: 80px;
    padding: 10px;
    width: 100%;
    object-fit: contain;
}

.cart-close {
    position: absolute;
    border: none;
    background: none;
    right: 15px;
    top: 5px;
    color: black;
    font-size: 24px;
}

.cart-close img {
    width: 15px;
    object-fit: contain;
    filter: invert();
}

.cart-img-div img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
}

.cart-product-card-info {
    padding: 6px 0 10px;
    display: grid;
    grid-template-columns:1fr;
    grid-template-rows:1fr 1fr;
    justify-content: flex-start;
}

.cart-product-card-info span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cart-product-card-info-amount {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
}

.cart-price-btn {
    align-self: center;
    font-size: 1.5rem;
    border: none;
    background: none;
}

.cart-product-card-info-amount input {
    width: 30%;
    text-align: center;
}

.cart-product-card-price {
    margin-right: 10px;
    padding: 6px 0 10px;
    display: grid;
    grid-template-columns:1fr;
    grid-template-rows:1fr 1fr;
    white-space: nowrap;
    overflow: hidden;
    text-align: end;
}

.cart-clean-btn {
    display: block;
    margin-top: 10px;
    font-family: var(--heading-font), sans-serif;
    background-color: white;
    padding: 8px 30px 10px 30px;
    border-radius: 5px;
    transition: 0.5s;
    border: 2px solid var(--accent-color);
    color: var(--accent-color);
}

.cart-clean-btn:hover {
    color: var(--contrast-color);;
    background: var(--accent-color);
}

.cart-delete-btn {
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    align-items: end;
    margin: 0 0 0 auto;
    padding: 0;
}

.cart-delete-btn img {
    height: 20px;
    width: 25px;
    object-fit: contain;
    padding: 0;
}