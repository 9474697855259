.catalog-page {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 312px 984px;
    grid-template-rows: auto;
    grid-gap: 24px;
}

.title-catalog {
    margin: 0 0 20px;
}

.products-container {
    width: 100%;
}

.products {
    width: 100%;
    margin: 20px 0 40px;
    align-self: start;
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.products-hidden {
    opacity: 0;
    transform: scale(0.98);
}

.products-visible {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.15s;
}

.list {
    height: 20px;
    width: 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.list:hover {
    opacity: 0.6;
}

.grid {
    height: 20px;
    width: 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
}

.grid:hover {
    opacity: 0.6;
}

.active-btn-group {
    opacity: 1;
    cursor: initial;
    display: none;
}

.grid-view {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 30px 15px;
    width: 100%;
}

.list-view {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
}

.products-all {
    display: flex;
    flex-direction: column;
}

.search-sort { 
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
}

.search-product {
    height: 100%;
    width: 40%;
}

.sort {
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sort span {
    margin-right: 10px;
    text-align: center;
    text-wrap: nowrap;
}

.filter-results {
    padding: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
    min-height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.active-filter {
    color: rgba(0, 0, 0, 0.8);
    margin: 0 5px;
}

.amount-products {
    margin: 0 10px 0 auto;
}

.filter {
    margin: 20px 0;
}

.slider {
    width: 90%;
    height: 2px;
    background-color: color-mix(in srgb, var(--accent-color), transparent 80%);
    margin-top: 20px;
}

.slider .thumb {
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-color:var(--background-color) ;
    border-radius: 50%;
    border: 2px solid var(--accent-color);
    top: -8px;
}

.track-1 {
    height : 100%;
    background-color: var(--accent-color);
}

.price-range {
    width: 90%;
    display: flex;
    flex-direction: row;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

}

.price-range-input {
    margin: 1px;
}

.subcategory-container{
    width: 90%;
    max-height: 100%;
    border: 1px solid color-mix(in srgb, var(--accent-color), transparent 85%);
    border-radius: 10px;
    overflow-y: hidden;
    scrollbar-color: var(--accent-color) color-mix(in srgb, var(--accent-color), white 95%);
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    padding: 10px 0 10px 10px;
}

.subcategory-container-scrollable {
    max-height: 200px;
    overflow-y: auto;
    scrollbar-color: var(--accent-color)
    color-mix(in srgb, var(--accent-color), white 95%);
    scrollbar-width: thin;
    scrollbar-gutter: stable;
}

.active-filter-name {
    display: flex;
    align-items: center;
    margin: 2.5px;
    padding: 5px 8px;
    background-color: color-mix(in srgb, var(--accent-color), transparent 80%);
    border-radius: 5px;
    font-size: 1em;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    cursor: pointer;
}

.active-filter-query{
    max-width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.active-filter-btn-delete{
    height: 10px;
    width: 10px;
    margin-left: 8px;
    filter: invert(1);
}

.active-filter-name:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 70%);
    color: color-mix(in srgb, var(--default-color), transparent 0%);
}
.clear-filters{
    cursor: pointer;
    box-shadow: inset 0 0 0 1px var(--accent-color);
}

.sort-direction-btn {
    cursor: pointer;
    background: none;
    border: none;
    padding: 2px;
    margin-left: 10px;
    margin-right: 5px;
}

.rotate-asc {
    width: 10px;
    transform: rotate(270deg);
    transition: transform 0.3s ease-in-out;
}

.rotate-desc {
    width: 10px;
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

.no-products-div{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
}