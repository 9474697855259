.categories-selection {
    width: 100%;
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}
.categories-selection-grid {
    margin: 0 auto;
    width: 1320px;
    display: grid;
    grid-template-columns:  repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-gap:10px 0;
}
