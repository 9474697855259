.orders {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.orders-div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid color-mix(in srgb, var(--accent-color), transparent 80%);
    padding: 20px;
    min-height: 270px;
}

.orders-component-title {
    position: absolute;
    top: -2px;
    left: 6%;
    padding: 0 5px;
    transform: translate(-50%, -50%);
    background: #fff;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1.2rem;
    color: var(--default-color);
}

.no-orders-div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.order {
    width:100%;
    border-radius: 10px;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}

.order-info {
    display: flex;
    flex-direction: row;
}

.order-address {
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 0.9rem;
    margin: 5px 0;
}

.order-product {
    display: flex;
    flex-direction: row;
}

.order-total {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    align-items: center;
}

.order-total-text {
    height:100%;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1rem;
    text-align: center;
    justify-content: center;
}

.order-id {
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1.1rem;
}

.order-status{
    height:100%;
    font-family: "SF Pro Rounded Regular", sans-serif;
    font-size: 1rem;
    text-align: center;
    justify-content: center;
    display:flex;
    margin: 0 0 0 auto;
}
.confirm-order {
    height:100%;
    font-family: "SF Pro Rounded Regular", sans-serif;
    font-size: 1rem;
    text-align: center;
    justify-content: center;
    display:flex;
    margin: 0 0 0 auto;
    cursor:pointer;
    /*text-decoration: underline;*/
    color:var(--accent-color);
}

.order-product-price {
    text-align: center;
    justify-content: center;
    display:flex;
    margin: 0 0 0 auto;
    opacity: 0.8;
}

.order-product-id {
    color: var(--default-color);
    opacity: 0.8;
    transition: opacity 0.3s;
    cursor: pointer;
}

.order-product-id:hover {
    color: var(--default-color);
    opacity: 1;
}

.order-delete {
    display:flex;
    margin: 0 0 0 auto;
    text-align: center;
    justify-content: center;
    background: color-mix(in srgb, var(--bs-danger), transparent 15%);
    padding: 2px 5px;
    border: none;
    color: var(--contrast-color);
    font-size: 0.8rem;
    border-radius: 4px;
    transition: 0.3s;
}

.order-delete:hover {
    background: color-mix(in srgb, var(--bs-danger), transparent 30%);
}
