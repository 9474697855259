.info-item-client {
    font-size: 1.2rem;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    text-decoration: underline;
}

.clients {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
}

.clients-div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid color-mix(in srgb, var(--accent-color), transparent 80%);
    padding: 20px;
    min-height: 270px;
}

.clients-component-title {
    position: absolute;
    top: -2px;
    left: 65px;
    padding: 0 5px;
    transform: translate(-50%, -50%);
    background: #fff;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1.2rem;
    color: var(--default-color);
}

.no-clients-div {
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
}

.no-clients-div.button {
    margin: auto auto 0 auto;
}

.profile-btn-add {
    border: none;
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 1rem;
    padding: 8px 26px;
    border-radius: 4px;
    transition: 0.3s;
}

.profile-btn-add:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}
