.loader-container {
    margin: auto;
}

/* Default */

.loader {
    margin: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid black;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    transition: all 0.3s ease-in-out;
}

.loader.loaded {
    background-color: color-mix(in srgb, white, transparent 100%);
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid lightblue;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

/* Small */

.loader-small {
    margin: 20px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-block;
    border-top: 2px solid black;
    border-right: 2px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    transition: all 0.3s ease-in-out;
}

.loader-small.loaded {
    background-color: color-mix(in srgb, white, transparent 100%);
}

.loader-small::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border-left: 2px solid lightblue;
    border-bottom: 2px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
