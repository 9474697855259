.toggle-switch {
    width: 40px;
    height: 20px;
    background-color: #ddd;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px; /* Дополнительный отступ для визуального баланса */
}

.toggle-switch.active {
    background-color: #4caf50; /* Цвет для активного состояния */
}

.toggle-knob {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: transform 0.3s ease;
}

.toggle-switch.active .toggle-knob {
    transform: translateX(20px);
}
