input[type="checkbox"],
input[type="text"],
input[type="password"],
textarea,
select {
    outline: none; /* Убирает стандартную обводку при фокусе */
    box-shadow: none; /* Убирает тень, которая может появляться при фокусе */
}

input[type="checkbox"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
    outline: none; /* Убирает стандартную обводку при фокусе */
    box-shadow: none; /* Убирает тень, которая может появляться при фокусе */
}

hr {
    border: none; /* Убираем стандартную границу */
    border-top: 1px solid #e0e0e0; /* Добавляем верхнюю границу для линии */
    margin: 10px 0; /* Отступы сверху и снизу */
}

.smart-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px 0 15px;
}

.smart-inputs {
    width: 450px;
}

.company-info {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 450px;
    height: 413px;
    background-color: #f8f9fa; /* Светло-серый фон для визуального отделения */
    border: 1px solid #e0e0e0; /* Легкая граница для карточки */
    border-radius: 8px; /* Закругленные углы */
    padding: 16px; /* Внутренние отступы для содержания */
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Легкая тень для придания глубины */
}

.company-info.address, .company-info.client, .profile-btn-add.address {
    width: 100%;
    height: 100%;
}

.company-info h3 {
    font-family: "SF Pro Rounded Regular", sans-serif;
    font-size: 1.1rem; /* Размер заголовка */
    color: #333; /* Цвет заголовка */
    display: flex; /* Flex для выравнивания иконки и текста */
    align-items: center; /* Центрирование по вертикали */
    margin: 0 auto 12px;
}

.company-info p {
    font-size: 1rem; /* Размер текста */
    color: #555; /* Цвет текста */
    margin: 4px 0; /* Отступы сверху и снизу для улучшенного восприятия */
    display: flex; /* Используем flex для выравнивания иконок, лейблов и текста */
    align-items: center; /* Вертикальное центрирование */
}

.company-info p strong {
    color: #000; /* Черный цвет для выделения ключевой информации */
    margin-right: auto; /* Автоматический отступ справа для выравнивания лейблов по левому краю */
    min-width: 90px; /* Минимальная ширина для выравнивания текста */
    display: inline-block; /* Отображение как блочный элемент для контроля ширины */
    text-align: left; /* Левое выравнивание для лейблов */
}

.company-info p .icon {
    margin-right: 15px; /* Отступ справа от иконки до текста */
    flex-shrink: 0; /* Предотвращает уменьшение иконки */
}

.company-info p .value {
    margin-left: auto; /* Автоматический отступ слева для выравнивания значений по правому краю */
    text-align: right; /* Выравнивание текста по правому краю для значений */
    flex-grow: 1; /* Расширение значения до правого края */
}

.company-info h3 .icon {
    width: 15px;
    margin-right: 8px;
    filter: invert();
}

.email-repeat-btn {
    background-color: #f8f9fa; /* Более светлый фон для меньшего акцента */
    color: #007bff; /* Сохраняем синий цвет для акцента на тексте */
    border: 1px solid #ced4da; /* Легкая граница для визуального разделения */
    border-radius: 4px; /* Мягкие закругленные углы */
    padding: 4px 12px; /* Меньше отступов, чтобы кнопка выглядела компактнее */
    font-size: 0.875rem; /* Уменьшаем размер шрифта */
    cursor: pointer; /* Курсор "рука" при наведении */
    transition: background-color 0.2s, color 0.2s; /* Плавный переход, но быстрее */
    margin-right: 5px;
}

.email-repeat-btn:hover {
    background-color: #e2e6ea; /* Легкое затемнение фона при наведении */
    color: #0056b3; /* Чуть более темный синий при наведении */
}

.email-repeat-btn:active {
    background-color: #dae0e5; /* Немного более темный фон при нажатии */
    color: #004085; /* Более темный синий для текста при нажатии */
}

.email-repeat-btn:disabled {
    background-color: #e9ecef; /* Светлый серый фон для неактивной кнопки */
    color: #6c757d; /* Серый цвет текста */
    cursor: not-allowed; /* Курсор "запрещено" для неактивной кнопки */
    border-color: #ced4da; /* Серый цвет границы для неактивной кнопки */
}

.profile {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid color-mix(in srgb, var(--accent-color), transparent 80%);
    padding: 10px;
}

.profile-component-title{
    position: absolute;
    top: -2px;
    left: 70px;
    padding: 0 5px;
    transform: translate(-50%, -50%);
    background: #fff;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1.2rem;
    color: var(--default-color);
}

.profile-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0;
}

.profile-input .form-control {
    width: 30%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.profile-input-nds {
    display: flex;
    align-content: center;
    gap: 10px;
    margin: 10px 0;
    font-family: "SF Pro Rounded Regular", sans-serif;
    border: none;
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 1rem;
    padding: 8px 10px;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    position: relative; /* Относительное позиционирование для чекбокса */
}

.profile-input-nds .form-check-input {
    position: absolute;
}

.profile-input-nds .checkbox-label {
    margin: 0 auto;
}

.profile-input-nds:not(:disabled):hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.custom-checkbox {
    width: 20px; /* Ширина чекбокса */
    height: 20px; /* Высота чекбокса */
    background-color: white; /* Цвет фона чекбокса */
    border: 2px solid var(--contrast-color); /* Граница чекбокса */
    border-radius: 4px; /* Закругленные углы для чекбокса */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, border-color 0.3s; /* Переход для изменения состояния */
}

.checked .custom-checkbox {
    background-color: var(--contrast-color); /* Цвет фона при активном чекбоксе */
    border-color: var(--contrast-color); /* Цвет границы при активном чекбоксе */
}

.checked .custom-checkbox::after {
    content: '✓'; /* Символ галочки */
    color: white; /* Цвет галочки */
    font-size: 16px; /* Размер галочки */
}

.profile-input-nds:hover .custom-checkbox {
    border-color: color-mix(in srgb, var(--default-color), transparent 30%); /* Цвет границы при наведении */
}

.profile-input > label {
    width: 100px;
    font-family: "SF Pro Rounded Regular", sans-serif;
    font-size: 1.1rem;
}

.profile-btn:disabled {
    background-color: color-mix(in srgb, var(--accent-color), transparent 80%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    border: none;
}

.profile-btn {
    border: none;
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 1rem;
    padding: 8px 26px;
    margin: 15px;
    border-radius: 4px;
    transition: 0.3s;
}

.profile-btn:not(:disabled):hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.profile-component-notification {
    margin: 10px 16px 20px;
    display: flex;
    background: rgb(255 215 148);
    align-items: center;
    justify-content: center;
    color: var(--default-color);
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1rem;
    height: 40px;
    border-radius: 5px;
}

.profile-component-notification img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-left: 10px;
    margin-right: auto;
}

.profile-component-notification span {
    position: absolute;
}
