.product-page {
    font-size: 1rem;
    width: 1320px;
    margin: 100px auto 0;
    padding: 20px 0;
}

.product-page-containt {
    display: grid;
    grid-template-columns:640px 640px;
    grid-template-rows: auto;
    grid-gap: 40px;
}

.product-img-slider {
    width: 100%;
}

.mySwiper2 {
    margin:10px 0;
    width:100%;
}

.mySwiper {
    margin:10px 0;
    width:100%;
}

.mySwiper2 img{
    height: 450px;
    width:100%;
    border-radius:10px;
}

.mySwiper img {
    border-radius:10px;
    width:100%;
    height:120px;
    margin: auto;
    cursor:pointer;
}

.product-right {
    display: flex;
    flex-direction: column;
}

.characteristics-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.characteristics-row.colored {
    background-color:color-mix(in srgb, var(--accent-color), transparent 95%);
}

.characteristic-name {
    font-family: "SF Pro Rounded Semibold", sans-serif;
}

.product-btn-basket {
    border: none;
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 1rem;
    padding: 8px 26px;
    border-radius: 4px;
    transition: 0.3s;
}

.product-btn-basket:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.product-price-contain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:20px;
}

.product-page-cart{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;
    width: max-content;
}

.product-page-cart-amount {
    display: flex;
    flex-direction: row;
    width: 150px;
    margin-right: 10px;
    height: 30px;
}

.product-page-cart-amount input {
    text-align: center;
}


.product-h {
    font-size: 1.75rem ;
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    font-weight: 400;
}