.profile-page {
    width: 1320px;
    margin: 100px auto 0;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
}

.profile-container {
    display: grid;
    grid-template-columns: 312px 984px;
    grid-gap: 20px;
    align-items: start;
    transition: height 0.2s ease-in-out;
}

.fade-profile-enter {
    opacity: 0;
    transform: scale(0.95);
}

.fade-profile-enter-active{
    transform: scale(1);
    opacity: 1;
    transition: opacity 300ms , transform 300ms;
}

.fade-profile-exit {
    opacity: 1;
    transform: scale(1);
}

.fade-profile-exit-active {
    transform: scale(0.95);
    opacity: 0;
    transition: opacity 300ms,transform 300ms;
}


