.btn-fill, .btn-fill:focus{
    font-family: var(--heading-font), sans-serif;
    color: var(--contrast-color);
    background: var(--accent-color);
    padding: 12px 40px;
    border-radius: 4px;
    transition: 0.3s;
}
.btn-fill:hover, .btn-fill:hover:focus{
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}
.btn-no-fill{
    font-family: var(--heading-font), sans-serif;
    padding: 12px 40px;
    border-radius: 5px;
    transition: 0.5s;
    border: 2px solid var(--contrast-color);
    color: var(--contrast-color);
}
.btn-no-fill:hover, .btn-fill:hover:focus{
    color: var(--contrast-color);;
    background: var(--accent-color);
    border: 2px solid var(--accent-color);
}