.path {
    display: flex;
    align-items: center;
    width:100%;
    height: 70px;
    margin: 100px 0 0;
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.path-body {
    display: flex;
    align-items: center;
    width: 1320px;
    margin: 0 auto;

    a {
        display: flex;
        align-items: center;
    }
}

.path-body img {
    color: black;
    height: 0.8rem;
}

.catalog-path-name {
    margin: 1rem;
}
