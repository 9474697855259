.info-item-address {
    font-size: 1.2rem;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    text-decoration: underline;
}

.addresses {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.addresses-div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid color-mix(in srgb, var(--accent-color), transparent 80%);
    padding: 20px;
    min-height: 270px;
}

.addresses-component-title {
    position: absolute;
    top: -2px;
    left: 80px;
    padding: 0 5px;
    transform: translate(-50%, -50%);
    background: #fff;
    font-family: "SF Pro Rounded Semibold", sans-serif;
    font-size: 1.2rem;
    color: var(--default-color);
}

.no-addresses-div {
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
}

.no-addresses-div.button {
    margin: auto auto 0 auto;
}

.profile-btn-add {
    border: none;
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 1rem;
    padding: 8px 26px;
    border-radius: 4px;
    transition: 0.3s;
}

.profile-btn-add:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.profile-btn-add.address-delete {
    padding: 5px 15px;
    background: color-mix(in srgb, var(--bs-danger), transparent 15%);
}

.profile-btn-add.address-delete:hover {
    background: color-mix(in srgb, var(--bs-danger), transparent 30%);
}
