.category {
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    font-size: 1rem;
    height: 100%;
    width: 100%;
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Добавляем тень */
    transition: transform 0.3s, box-shadow 0.3s; /* Добавляем плавные переходы для эффекта */
}

.category-image-wrapper {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background-color: white;
}

.category-name-wrapper {
    color: var(--default-color);
    padding: 10px;
}

.category img {
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.category:hover {
    transform: scale(1.05); /* Увеличиваем размер при наведении */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}