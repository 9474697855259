.profile-navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid color-mix(in srgb, var(--accent-color), transparent 80%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    align-items: center;
}

.profile-navigation-link {
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    font-family: "SF Pro Rounded Regular", sans-serif;
    font-size: 1.1rem;
    color: var(--default-color);
    transition: all 0.2s ease-in-out;
}

.profile-navigation-link::before {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2px;
    width: 3px;
    background: transparent url('../../../public/img/Rectangle.svg') no-repeat center center;
    background-size: cover;
    transition: background-color 0.2s ease-in-out;
}

.active-link::before {
    transform: scaleY(0.7);
    display: flex;
    transition: transform 0.3s ease-in-out;
}

.profile-navigation-icon {
    margin: 10px;
}

.profile-navigation-icon.right {
    margin: 10px 20px 10px auto;
}

.profile-navigation-icon.right.verify-status {
    font-size: 0.8rem;
    font-weight: bold;
    border: 2px solid rgb(255, 193, 7, 0.5);
    background-color: rgba(255, 193, 7, 0.5);
    color: rgb(108, 84, 0);
    padding: 2px 7px;
    border-radius: 5px;
}

.profile-navigation-icon.right.verify-status.confirmed {
    font-size: 0.8rem;
    font-weight: bold;
    border: 2px solid rgba(60, 220, 1, 0.5);
    background-color: rgba(60, 220, 1, 0.5);
    color: rgb(23, 96, 0);
    padding: 2px 7px;
    border-radius: 5px;
}

.profile-navigation-icon.right.verify-status .icon {
    font-size: 1rem;
    margin: 0 5px 2px 0;
}

.profile-navigation-icon.right img {
    width: 17px;
    height: 17px;
    object-fit: contain;
    filter: invert();
}

.profile-navigation-link-profile:hover {
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
}

.profile-navigation-link:hover {
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
}

.profile-navigation-link:last-child:hover {
    background-color: color-mix(in srgb, rgb(223, 21, 41), transparent 90%);
}

.active-link {
    /*border-left: 3px solid color-mix(in srgb, var(--accent-color), transparent 50%);*/
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
}


.logout {
    color: rgba(223, 21, 41, 0.75);
    border: none;
    background-color: transparent;
    padding: 0;
}

.logout:hover {
    color: #df1529;
}

