/* Общий стиль для overlay (фон модального окна) */
.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 17;
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.modal-overlay.ReactModal__Overlay--after-open {
    opacity: 1;
}

.modal-overlay.ReactModal__Overlay--before-close {
    opacity: 0;
}

/* Общий стиль для контейнера модального окна */
.modal-container {
    width: 100%;
    transition: height 0.3s ease-in-out;
}

/* Общий стиль для содержимого модального окна */
.modal-content {
    background: color-mix(in srgb, var(--background-color), transparent 10%);
    align-items: center;
    justify-content: center;
    width: 30%;
    overflow: auto;
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.2s;
    transform: translateY(+200%);
    opacity: 0;
}

.modal-content.ReactModal__Content--after-open {
    transform: translateY(0);
    opacity: 1;
}

.modal-content.ReactModal__Content--before-close {
    transform: translateY(+200%);
    opacity: 0;
}

/* Modal form common (on need) */

.common-modal-container {
    height: 330px;
}

.common-modal-container-wide {
    height: 410px;
}

.common-div-form {
    font-size: 1rem;
    padding: 1.5em 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.common-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.common-form-label {
    margin-top: 10px;
}

.common-form-input {
    width: 100%;
    border: 1px solid color-mix(in srgb, var(--accent-color), transparent 10%);
}

.common-form-submit-block {
    height: 70px;
    width: 100%;
}

.error {
    border: 1px solid #df1529;
}

.error:focus {
    border: 1px solid #df1529;
    box-shadow: 0 0 0 .2rem rgba(223, 21, 41, 0.25);
}

.common-btn {
    width: 100%;
    border: none;
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 1rem;
    padding: 8px 26px;
    margin: 20px 0;
    border-radius: 4px;
    transition: 0.3s;
}

.common-btn.action-modal {
    margin: 10px 0 0;
}

.common-btn.action-modal:first-child {
    margin-top: 20px;
}

.common-btn.action-modal:last-child {
    margin-bottom: 20px;
}

.common-btn.action-modal.cancel-btn {
    color: var(--accent-color);
    background: color-mix(in srgb, var(--accent-color), transparent 90%);
    transition: all ease-in-out 0.3s;
}

.common-btn.action-modal.cancel-btn:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 80%);
}

.common-btn:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.common-switcher {
    cursor: pointer;
    color: var(--accent-color);
}

.common-error-span {
    height: 15px;
    color: #df1529;
    font-size: 0.9rem;
    margin: 0 0 5px auto;
}

.input-container {
    position: relative;
    display: inline-block;
}

.toggle-img {
    position: absolute;
    object-fit: contain;
    height: 15px;
    width: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.close-modal-btn {
    position: absolute;
    right: 10px;
    top: 5px;
    background: transparent;
    border: none;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.8);
}

.fade-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: opacity 300ms, height 300ms , transform 300ms, margin 300ms, padding 300ms;
}

.fade-exit {
    opacity: 1;
    transform: scale(1);
}

.fade-exit-active {
    transform: scale(0.8);
    opacity: 0;
    transition: opacity 300ms, height 300ms, transform 300ms, margin 300ms, padding 300ms;
}
