.product-card-list {
    display: flex;
    flex-direction: row;
    width: 980px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    background: linear-gradient(to bottom, transparent 0%, color-mix(in srgb, var(--accent-color), transparent 95%) 0%);
    cursor: pointer;
}

.product-card-list-content {
    transition: all 0.2s ease-in-out;
    width: 980px;
    height: 60px;
    display: grid;
    grid-template-columns: 80px repeat(5, 1fr);
    grid-template-rows: repeat(2, 25px);
    grid-column-gap: 5px;
    padding: 5px;
}

.product-card-list-content.hovered {
    width: 920px;
}

.product-card-list:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(101%);
}

.product-card-list-img {
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 100%;
    width: 60px;
    object-fit: contain;
    justify-self: start;
}

.product-card-list-img-hint {
    grid-column: 1;
    grid-row: 1 / span 2;
    max-height: 350px;
    object-fit: contain;
    justify-self: start;
}

.product-card-list-name {
    grid-column: 2 / -2;
    grid-row: 1;

    font-family: 'SF Pro Rounded Regular', sans-serif;
    width: 95%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 1rem;
}

.product-card-list-stock {
    grid-column: 3;
    grid-row: 2;

    align-self: start;
    font-size: 1rem;
    font-family: 'SF Pro Rounded Regular', sans-serif;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.product-card-list-article {
    grid-column: 2;
    grid-row: 2;

    justify-self: start;
    align-self: center;
    padding: 2px 8px;
    background-color: color-mix(in srgb, var(--accent-color), transparent 80%);
    border-radius: 15px;
    font-size: 0.8em;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.product-card-list-article:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 70%);
    color: color-mix(in srgb, var(--default-color), transparent 0%);
}

.product-card-list-hidden {
    visibility: hidden;
    opacity: 0;
}

.product-card-list-retailcost-tooltip {
    grid-column: -1;
    grid-row: 1;
    z-index: 1;
    margin-right: 5px;
}

.product-card-list-retailcost {
    grid-column: -2;
    grid-row: 1;

    width: 120px;
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    align-self: center;
    text-align: start;
    justify-self: end;
}

.product-card-list-retailcost-icon {
    grid-column: -1;
    grid-row: 1;

    margin-right: 20px;
    width: 15px;
    object-fit: contain;
    align-self: center;
    justify-self: end;
}

.product-card-list-wholesalecost-tooltip {
    grid-column: -1;
    grid-row: 2;
    z-index: 1;
    margin-right: 5px;
}

.product-card-list-wholesalecost {
    grid-column: -2;
    grid-row: 2;

    width: 120px;
    font-family: 'SF Pro Rounded Semibold', sans-serif;
    align-self: center;
    text-align: start;
    justify-self: end;
}

.product-card-list-wholesalecost-icon {
    grid-column: -1;
    grid-row: 2;

    margin-right: 20px;
    width: 15px;
    object-fit: contain;
    align-self: center;
    justify-self: end;
}

.product-card-list-cart {
    width: 0;
    height: 60px;
    transition: all 0.2s ease-in-out;
}

.product-card-list-cart.hovered {
    width: 60px;
}

.product-card-list-cart .add {
    background-color: var(--rs-btn-primary-bg);
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
}

.product-card-list-cart .remove {
    background-color: var(--rs-btn-default-hover-bg);
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
}

.product-card-list-cart .icon {
    width: 20px;
    object-fit: contain;
}

.product-card-list-cart .add .icon {
    filter: invert(1);
}
