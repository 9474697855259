/* Специфический стиль для аутентификационного модального окна (высота) */
.auth-modal-container {
    height: 330px; /* Базовая высота для логина */
}

.auth-modal-container-signup {
    height: 410px; /* Высота для регистрации */
}

/* Стили для форм внутри модального окна */
.auth-div-form {
    padding: 1.5em 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.auth-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.auth-form-input {
    width: 100%;
    border: 1px solid color-mix(in srgb, var(--accent-color), transparent 10%);
}

.error {
    border: 1px solid #df1529;
}

.error:focus {
    border: 1px solid #df1529;
    box-shadow: 0 0 0 .2rem rgba(223, 21, 41, 0.25);
}

.auth-btn {
    border: none;
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 1rem;
    padding: 8px 26px;
    margin: 20px 0;
    border-radius: 4px;
    transition: 0.3s;
}

.auth-btn:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.auth-switcher {
    cursor: pointer;
    color: var(--accent-color);
}

.auth-error-span {
    height: 15px;
    color: #df1529;
    font-size: 0.9rem;
    margin: 0 0 5px auto;
}

.input-container {
    position: relative;
    display: inline-block; /* или "flex" */
}

.toggle-img {
    position: absolute;
    object-fit: contain;
    height: 15px;
    width: 20px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.close-modal-btn {
    position: absolute;
    right: 10px;
    top: 5px;
    background: transparent;
    border: none;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.8);
}

.fade-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: opacity 300ms, height 300ms , transform 300ms, margin 300ms, padding 300ms;
}

.fade-exit {
    opacity: 1;
    transform: scale(1);
}

.fade-exit-active {
    transform: scale(0.8);
    opacity: 0;
    transition: opacity 300ms, height 300ms, transform 300ms, margin 300ms, padding 300ms;
}
