.brand-logo {
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: end;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    border-radius: 10px;
    border: solid 1px color-mix(in srgb, var(--accent-color), transparent 95%);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.brand-logo img {
    height: 100%;
    padding: 10px 10px;
    width: 100%;
    object-fit: contain;
    transition: 0.3s ease-in-out;
    opacity: 1;
    filter: grayscale(100%) ;
}

.brand-logo img:hover {
    filter: none;
    opacity: 1;
}

.brand-logo:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    transform: scale(1.05); 
}

.row .swiper-button-prev-custom,
.row .swiper-button-next-custom {
    top: auto;
    z-index: 10;
    position: absolute;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); 
    border: solid 1px rgba(0, 0, 0, 0.15);
    opacity: 1;
    background-color: white;
    color: var(--heading-color-dark); /* Цвет стрелок */
    border-radius: 50%; /* Закругление углов для круглых стрелок */
    width: 40px; /* Ширина стрелок */
    height: 40px; /* Высота стрелок */
    transition: 0.3s ease-in-out;
}
.row .swiper-button-prev-custom:after, .row .swiper-button-next-custom:after {
    font-family: swiper-icons, sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    margin: auto;
}

.swiper-button-prev-custom:hover,
.swiper-button-next-custom:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
}
