.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: color-mix(in srgb, var(--accent-color), white 95%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    z-index: 20;
    width: 100%;
    padding: 10px 5px 10px 0;
}

.search-dropdown-scrollable {
    max-height: 250px;
    overflow-y: auto;
    scrollbar-color: var(--accent-color) color-mix(in srgb, var(--accent-color), white 95%);
    scrollbar-width: thin;
    scrollbar-gutter: stable;
}

.search-dropdown-item-capsule {
    cursor: pointer;
    padding: 5px 6px 5px 10px;
    overflow: hidden;
}

.search-dropdown-item-capsule:first-child {
    padding-top: 10px;
}

.search-dropdown-item-capsule:last-child {
    padding-bottom: 10px;
}

.search-dropdown-item {
    width: 100%;
    position: relative;
    display: flex; /* Flexbox для выравнивания иконки и текста */
    align-items: center; /* Вертикальное выравнивание по центру */
    padding: 12px 16px; /* Внутренние отступы */
    transition: all 0.2s ease;
    border-radius: 10px;
}

.search-dropdown-item:last-child {
    border-bottom: none;
}

/* Стили для ховера элемента */
.search-dropdown-item-capsule:hover .search-dropdown-item {
    background-color: color-mix(in srgb, var(--accent-color), transparent 85%);
}

/* Иконка элемента */
.search-dropdown-item-icon {
    width: 40px; /* Размер иконки */
    height: 40px; /* Размер иконки */
    border-radius: 8px; /* Скругленные углы иконки */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px; /* Отступ справа от иконки */
    overflow: hidden; /* Скрываем излишки изображения за пределами контейнера */
    background-color: #f5f5f5; /* Легкий серый фон для иконки */
}

/* Стиль для изображения иконки */
.search-dropdown-item-icon img {
    position: relative;
    width: 100%; /* Иконка заполняет всю ширину контейнера */
    height: 100%; /* Иконка заполняет всю высоту контейнера */
    object-fit: contain; /* Изображение обрезается, чтобы заполнить контейнер, сохраняя аспект */
}

/* Текстовая часть элемента */
.search-dropdown-item-text {
    display: flex;
    flex-direction: column; /* Расположение элементов текста в колонку */
    justify-content: center;
}

/* Заголовок элемента */
.search-dropdown-item-title {
    font-size: 16px; /* Размер заголовка */
    font-weight: bold; /* Жирный шрифт */
    margin: 0; /* Сброс отступов */
    color: #333; /* Цвет заголовка */
}

/* Описание элемента (количество продуктов) */
.search-dropdown-item-description {
    font-size: 14px; /* Размер текста описания */
    color: #888; /* Цвет текста описания */
    margin: 4px 0 0; /* Отступы */
}

/* Иконка стрелки */
.search-dropdown-item-arrow {
    margin-left: auto; /* Отступ слева для выравнивания вправо */
    margin-right: 5px;
    display: flex; /* Flexbox для выравнивания */
    align-items: center; /* Центрирование по вертикали */
    justify-content: center; /* Центрирование по горизонтали */
    width: 20px;
    height: 20px;
    opacity: 0.5;
    transition: all 0.2s ease;
}

/* Стили для изображения стрелки */
.search-dropdown-item-arrow img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Стили для ховера стрелки */
.search-dropdown-item-capsule:hover .search-dropdown-item-arrow {
    margin-right: 0;
    opacity: 1;
}